import VerticalCardProduct from '../components/VerticalCardProduct.jsx'
import './Motos.css'

const Motos = () => {
  return (
    <div>   
      
        <VerticalCardProduct category={"Moto"} heading={"Motos"}/>
   
      <div className="info-section">
    <h3 className="info-title">Encuentra tu moto ideal en 4 rent Bogotá y Medellín</h3>
    <p className="info-text">Te ofrecemos una amplia gama de opciones para que encuentres la moto perfecta para tus necesidades de movilidad, ya sea en Bogotá o Medellín. Con precios competitivos y un proceso de alquiler rápido y sencillo, estamos aquí para hacer que tu experiencia sea lo más conveniente posible en ambas ciudades.</p>
    <p className="info-text">Contamos con una variedad de motos disponibles para alquiler, desde modelos clásicos hasta las últimas novedades. Ya sea que necesites una moto para desplazamientos diarios por la ciudad o para aventuras más largas, en Bogotá o Medellín, tenemos la moto adecuada para ti.</p>
    <p className="info-text">Nuestro proceso de alquiler es rápido y sin complicaciones. Solo necesitas tu identificación, licencia de conducción y una forma de pago válida para comenzar a disfrutar de tu moto alquilada. ¡Es así de fácil tanto en Bogotá como en Medellín!</p>
    <p className="info-text">Nuestro equipo está aquí para ayudarte en cada paso del proceso. Ya sea que tengas preguntas sobre nuestras opciones de alquiler en Bogotá o Medellín, o necesites asistencia durante tu período de alquiler, estamos disponibles para brindarte el apoyo que necesitas.</p>
    <p className="info-text">No esperes más para disfrutar de la libertad y la emoción de conducir una moto por las calles de Bogotá o Medellín. ¡Reserva tu moto con nosotros y comienza tu próxima aventura sobre dos ruedas hoy mismo!</p>
</div>
    </div>
  )
}

export default Motos