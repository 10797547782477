import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BannerProduct from '../components/BannerProduct';
import HorizontalCardProduct from '../components/HorizontalCardProduct';
import StepsComponent from '../components/StepsComponent/StepsComponent';
import './Home.css';

const Home = () => {
  const WhatsappInvite = ({ phoneNumber }) => {
    useEffect(() => {
      const videoElement = document.querySelector('.whatsapp-invite-video');
      if (videoElement) {
        videoElement.play().catch(error => {
          console.error('Error intentando reproducir el video:', error);
        });
      }
    }, []);

    return (
      <div className="whatsapp-invite-container">
        <a 
          href={`https://wa.me/${phoneNumber}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="whatsapp-invite-link"
        >
          ¡Haz clic aquí para contactarnos en WhatsApp!
        </a>
      </div>
    );
  };

  return (
    <main>
      <BannerProduct />
      <WhatsappInvite phoneNumber="573001234567" />

      <HorizontalCardProduct category={"Moto página de inicio"} heading={"Motos"} />

      <div className="ver-motos">
        <Link to="/motos">Ver todas las motos</Link>
      </div>

      <StepsComponent />

   

      <section className="contact-container">
        <div className="contact-inner-container">
          <div className="contact-content">
            <div className="contact-description-container">
              <h2 className="contact-title">¡Reserva tu moto con 4 Rent ahora mismo!</h2>
              <p className="contact-description">
                Experimenta la emoción de recorrer las calles de Bogotá y Medellín sobre dos ruedas. ¡No esperes más para vivir esta aventura única!
              </p>
              <p className="contact-description">
                En 4Rent, ofrecemos una amplia gama de motos para alquilar en la ciudad de Medellín y Bogotá, Colombia. Tenemos la moto perfecta para ti.
              </p>
              <p className="contact-description">
                Nuestro objetivo es brindarte una experiencia de alquiler sin complicaciones para que puedas disfrutar al máximo tu viaje.
              </p>
            </div>
            <div className="contact-button-container">
              <Link to="/contacto" className="btn-primary">Contáctanos aquí</Link>
            </div>
          </div>
        </div>
      </section>

    </main>
  );
};

Home.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default Home;
