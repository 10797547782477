// src/pages/PaymentSuccess.jsx

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearCart } from "../store/cartSlice";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./PaymentSuccess.css";
import SummaryApi from "../common";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionDetails, setTransactionDetails] = useState(null);

  // Obtenemos parámetros de la URL
  const queryParams = new URLSearchParams(location.search);
  const transactionStatus = queryParams.get("status"); // Se espera "APPROVED" si es exitoso
  const referenceCode = queryParams.get("reference");
  const amountInCents = queryParams.get("amount_in_cents");

  useEffect(() => {
    // Si es APROVED, limpiar carrito y consultar detalles si lo deseas
    if (transactionStatus === "APPROVED") {
      dispatch(clearCart());
      if (referenceCode) {
        fetchTransactionDetails(referenceCode);
      }
    }
  }, [dispatch, transactionStatus, referenceCode]);

  const fetchTransactionDetails = async (referenceCode) => {
    try {
      // Aquí llamas a tu endpoint si lo deseas
      // Suponiendo que en tu SummaryApi tengas algo como getTransactionDetails
      if (SummaryApi.getTransactionDetails) {
        const response = await axios.get(
          `${SummaryApi.getTransactionDetails.url}/${referenceCode}`
        );
        setTransactionDetails(response.data);
      }
    } catch (error) {
      console.error("Error al obtener los detalles de la transacción:", error);
    }
  };

  return (
    <div className="payment-success-container">
      {transactionStatus === "APPROVED" ? (
        <>
          <h1>¡Gracias por tu compra!</h1>
          <p>Tu pago ha sido procesado exitosamente.</p>
          {referenceCode && (
            <p>
              <strong>Referencia: </strong>
              {referenceCode}
            </p>
          )}
          {amountInCents && (
            <p>
              <strong>Monto:</strong> {parseInt(amountInCents, 10) / 100} USD
            </p>
          )}
          {/* Si deseas, muestras transactionDetails */}
          {transactionDetails && transactionDetails.reservationDetails && (
            <div className="product-details">
              <h3>Detalles de los productos:</h3>
              {transactionDetails.reservationDetails.map((item, index) => (
                <div key={index} className="product-item">
                  <p>
                    <strong>Producto ID:</strong> {item.productId}
                  </p>
                  {/* etc... */}
                </div>
              ))}
            </div>
          )}
          <button onClick={() => navigate("/")}>Volver al Inicio</button>
        </>
      ) : transactionStatus === "DECLINED" ? (
        <>
          <h1>Pago Rechazado</h1>
          <p>Tu pago fue rechazado. Por favor, intenta nuevamente.</p>
          <button onClick={() => navigate("/cart")}>Volver al Carrito</button>
        </>
      ) : (
        <>
          <h1>Pago Pendiente</h1>
          <p>
            Tu pago está en proceso o no se detectó el parámetro
            <em> status=APPROVED</em>. Te notificaremos cuando se confirme.
          </p>
          <button onClick={() => navigate("/")}>Volver al Inicio</button>
        </>
      )}
    </div>
  );
};

export default PaymentSuccess;
