import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import { GrSearch } from 'react-icons/gr';
import { FaRegCircleUser, FaWpforms } from 'react-icons/fa6';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import { setUserDetails } from '../store/userSlice';
import Context from '../context/index';
import Logo from './Logo'; 
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import './Header.css'; 
const Header = () => {
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const context = useContext(Context);
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false); 
  const [menuDisplay, setMenuDisplay] = useState(false); 
  const [search, setSearch] = useState({ query: '' }); 
  const [anchorElMotos, setAnchorElMotos] = useState(null); 
  const [data, setData] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const handleLogout = async () => {
    try {
      const response = await fetch(SummaryApi.logout_user.url, {
        method: SummaryApi.logout_user.method,
        credentials: 'include',
      });
      const dataResp = await response.json();

      if (dataResp.success) {
        toast.success(dataResp.message);
        dispatch(setUserDetails(null));
        navigate('/');
      } else if (dataResp.error) {
        toast.error(dataResp.message);
      }
    } catch (error) {
      console.error('Error al hacer logout:', error);
      toast.error('Error al cerrar sesión.');
    }
  };

  const handleSearch = () => {
    const params = new URLSearchParams();
    if (search.query) params.append('q', search.query);
    params.append('description', true);

    navigate(`/search?${params.toString()}`);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleMotosMenuOpen = (event) => {
    setAnchorElMotos(event.currentTarget);
  };
  const handleMotosMenuClose = () => {
    setAnchorElMotos(null);
  };
  useEffect(() => {
    const fetchMotos = async () => {
      setLoading(true);
      try {
        const categoryProduct = await fetchCategoryWiseProduct('moto');
        setLoading(false);

        if (categoryProduct && categoryProduct.data) {
          setData(categoryProduct.data);
        } else {
          setData([]);
          console.log('No se encontraron productos en la categoría "moto".');
        }
      } catch (error) {
        console.error('Error al obtener productos de "moto":', error);
        setData([]);
        setLoading(false);
      }
    };

    fetchMotos();
  }, []);
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', color: 'black' }}
    >
      <RouterLink to="/" style={{ textDecoration: 'none' }}>
        <Logo />
      </RouterLink>
      <Divider />
      <ul className="mobile-navigation">
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/motos">Motos</NavLink>
        </li>
        <li>
          <NavLink to="/seguros">Seguros</NavLink>
        </li>
        <li>
          <NavLink to="/requisitos4rent">Requisitos</NavLink>
        </li>
        <li>
          <NavLink to="/condiciones4rent">Condiciones</NavLink>
        </li>
        <li>
          <NavLink to="/rentar">Rentar</NavLink>
        </li>
      </ul>
    </Box>
  );
  return (
    <>
      <AppBar component="nav" position="static" sx={{ bgcolor: 'white', height: '70px' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{
              color: 'black',
              mr: 2,
              display: { sm: 'none' },
            }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <Logo />
          </RouterLink>

          {/* Barra de búsqueda */}
          <div className="search-bar">
            <input
              type="text"
              placeholder="Buscar moto 4rent..."
              className="search-input"
              onChange={(e) => setSearch({ ...search, query: e.target.value })}
              value={search.query}
            />
            <div className="search-button" onClick={handleSearch}>
              <GrSearch />
            </div>
          </div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'center',
              color: 'black',
            }}
          >
            <Button
              color="inherit"
              onClick={handleMotosMenuOpen}
              aria-controls="motos-menu"
              aria-haspopup="true"
              sx={{ textTransform: 'none', fontSize: '1rem', color: 'black' }}
            >
              Motos
            </Button>
            <Menu
              id="motos-menu"
              anchorEl={anchorElMotos}
              open={Boolean(anchorElMotos)}
              onClose={handleMotosMenuClose}
              sx={{
                '& .MuiMenuItem-root': {
                  textTransform: 'none',
                  fontSize: '1rem',
                },
              }}
            >
              {loading ? (
                <MenuItem>Cargando...</MenuItem>
              ) : data.length > 0 ? (
                data.map((product) => (
                  <MenuItem
                    key={product._id}
                    component={RouterLink}
                    to={`/product/${product._id}`}
                    onClick={handleMotosMenuClose}
                  >
                    {product.productName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No hay productos</MenuItem>
              )}
            </Menu>
            <NavLink to="/" style={{ textDecoration: 'none' }}>
              <Button color="inherit" sx={{ textTransform: 'none', fontSize: '1rem', color: 'black' }}>
                Home
              </Button>
            </NavLink>
          </Box>
          <div className="header-actions" style={{ display: 'flex', alignItems: 'center' }}>
            {user?._id && (
              <div
                className="user-icon"
                onClick={() => setMenuDisplay((prev) => !prev)}
                style={{ cursor: 'pointer', position: 'relative', marginRight: '16px' }}
              >
                {user?.profilePic ? (
                  <img
                    src={user.profilePic}
                    alt={user?.name || 'Profile'}
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                  />
                ) : (
                  <FaRegCircleUser size={40} />
                )}
                {menuDisplay && (
                  <div
                    className="menu-dropdown"
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '45px',
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '10px',
                      zIndex: 1000,
                    }}
                  >
                    {user.role === 'ADMIN' ? (
                      <>
                        <RouterLink
                          to="/admin-panel/all-products"
                          onClick={() => setMenuDisplay(false)}
                          style={{
                            display: 'block',
                            padding: '8px 0',
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                        Admin
                        </RouterLink>
                        <RouterLink
                          to="/admin-panel/all-users"
                          onClick={() => setMenuDisplay(false)}
                          style={{
                            display: 'block',
                            padding: '8px 0',
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                          Usuarios
                        </RouterLink>
                      </>
                    ) : (
                      <>
                        {/* <RouterLink
                          to="/perfil"
                          onClick={() => setMenuDisplay(false)}
                          style={{
                            display: 'block',
                            padding: '8px 0',
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                          Perfil
                        </RouterLink>
                         <RouterLink
                          to="/orders"
                          onClick={() => setMenuDisplay(false)}
                          style={{
                            display: 'block',
                            padding: '8px 0',
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                          Pedidos
                        </RouterLink>  */}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            {/* Carrito */}
            {user?._id && (
              <RouterLink
                to="/cart"
                className="cart-link"
                style={{ position: 'relative', marginRight: '16px', color: 'black' }}
              >
                <FaWpforms size={24} />
                {context?.cartProductCount > 0 && (
                  <div
                    className="cart-count"
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-10px',
                      backgroundColor: 'red',
                      color: 'white',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {context.cartProductCount}
                  </div>
                )}
              </RouterLink>
            )}
            <div className="auth-button">
              {user?._id ? (
                <button
                  onClick={handleLogout}
                  style={{
                    background: '#f6a700',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '1rem',
                    borderRadius: '4px',
                    padding: '6px 12px',
                  }}
                >
                  Logout
                </button>
              ) : (
                <RouterLink
                  to="/login"
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    fontSize: '1rem',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '6px 12px',
                  }}
                >
                  Login
                </RouterLink>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          bgcolor: '#f0f0f0',
          padding: '10px',
          borderBottom: '1px solid #ccc',
        }}
      >
        <ul
          className="navigation-menu-horizontal"
          style={{ display: 'flex', listStyle: 'none', padding: 0, margin: 0 }}
        >
          <li style={{ marginRight: '16px' }}>
            <NavLink to="/" style={{ textDecoration: 'none', color: 'black' }}>
              Home
            </NavLink>
          </li>
          <li style={{ marginRight: '16px' }}>
            <NavLink to="/motos" style={{ textDecoration: 'none', color: 'black' }}>
              Motos
            </NavLink>
          </li>
          <li style={{ marginRight: '16px' }}>
            <NavLink to="/seguros" style={{ textDecoration: 'none', color: 'black' }}>
              Seguros
            </NavLink>
          </li>
          <li style={{ marginRight: '16px' }}>
            <NavLink to="/requisitos4rent" style={{ textDecoration: 'none', color: 'black' }}>
              Requisitos
            </NavLink>
          </li>
          <li style={{ marginRight: '16px' }}>
            <NavLink to="/condiciones4rent" style={{ textDecoration: 'none', color: 'black' }}>
              Condiciones
            </NavLink>
          </li>
          <li>
            <NavLink to="/rentar" style={{ textDecoration: 'none', color: 'black' }}>
              Rentar
            </NavLink>
          </li>
        </ul>
      </Box>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '220px',
              color: 'black',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
