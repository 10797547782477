// src/pages/ProductDetails.jsx

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SummaryApi from '../common'; // Asegúrate de que esta ruta es correcta
import CategoryWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import displayCOPCurrency from '../helpers/displayCurrency'; // Función para formatear la moneda COP

import {
  TbCircuitMotor,
  TbCylinderPlus,
  TbCarCrane,
} from 'react-icons/tb';
import { AiOutlineSisternode } from 'react-icons/ai';
import { SlEnergy } from 'react-icons/sl';
import {
  GiGasPump,
  GiHorseHead,
  GiSpeedometer,
  GiFullMotorcycleHelmet,
  GiLifeJacket,
  GiColombia,
} from 'react-icons/gi';
import { CgChanel } from 'react-icons/cg';
import { SiVirginmedia } from 'react-icons/si';
import { FaBoxArchive } from 'react-icons/fa6';
import { IoPhonePortraitSharp } from 'react-icons/io5';
import { RiSecurePaymentFill } from 'react-icons/ri';
import { MdPermPhoneMsg } from 'react-icons/md';
import './ProductDetails.css'; // Asegúrate de tener los estilos adecuados
import { useDispatch } from 'react-redux';
import { addToCart } from '../store/cartSlice'; 
import PropTypes from 'prop-types';

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: '',
    brandName: '',
    category: '',
    productImage: [],
    description: '',
    price: '',
    sellingPrice: '',
    motor: '',
    cilindrada: '',
    alimentacion: '',
    diametroXCarrera: '',
    potencia: '',
    torqueMaximo: '',
    arranque: '',
    capacidadCombustible: '',
    transmision: '',
    maletero: '',
    baulCapacidad: '',
    planesTarifas: {
      alquilerPorHoras: [],
      alquilerPorDias: [],
      planesSemanales: [],
      planesQuincenales: [],
      planesMensuales: [],
      adicionales: {
        kilometroAdicionalCOP: 0,
        horaAdicionalCOP: 0,
        depositoFianzaCOP: 0,
      },
    },
    stock: 0,
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(true);
  const [activeImage, setActiveImage] = useState('');
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 });
  const [zoomImage, setZoomImage] = useState(false);

  // Estado para el formulario de reserva
  const [reservationDetails, setReservationDetails] = useState({
    planType: '',
    planOption: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    additionalProducts: [],
    city: '',
  });

  const { planType } = reservationDetails;

  // Estados para el total y días calculados
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedDays, setSelectedDays] = useState(0); 
  const [originalDays, setOriginalDays] = useState(0); 

  // Fecha actual
  const todayDate = new Date().toISOString().split('T')[0];

  // Productos adicionales
  const additionalProductsList = [
    { name: 'Impermeable', price: 15000, unit: 'COP', perDay: false },
    { name: 'Servicio de lavado', price: 15000, unit: 'COP', perDay: false },
    { name: 'Abrigo o jacket', price: 9000, unit: 'COP', perDay: false },
    { name: 'Guantes (Gloves)', price: 4000, unit: 'COP', perDay: false },
    { name: 'Rodilleras', price: 5000, unit: 'COP', perDay: false },
    { name: 'Gopro 9', price: 39000, unit: 'COP', perDay: false },
    { name: 'Insta 360 x3', price: 79000, unit: 'COP', perDay: false },
    { name: 'Entrega domicilio', price: 59000, unit: 'COP', perDay: false },
    { name: 'Devolución domicilio', price: 59000, unit: 'COP', perDay: false },
  ];

  // Calcular la diferencia en días
  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = end - start;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  // Fetch de detalles
  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.productDetails.url, {
        method: SummaryApi.productDetails.method,
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ productId: params?.id }),
      });
      const dataResponse = await response.json();
      setLoading(false);
      setData(dataResponse?.data || {});
      setActiveImage(dataResponse?.data?.productImage?.[0] || '');
    } catch (error) {
      console.error('Error al obtener detalles del producto:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [params]);

  const handleMouseEnterProduct = (imageURL) => {
    setActiveImage(imageURL);
  };

  const handleZoomImage = useCallback((e) => {
    setZoomImage(true);
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomImageCoordinate({ x, y });
  }, []);

  const handleLeaveImageZoom = () => {
    setZoomImage(false);
  };

  const handleReservationChange = (e) => {
    const { name, value } = e.target;
    setReservationDetails((prev) => {
      let updated = { ...prev, [name]: value };
      // Ajustar fechas si es necesario
      if (name === 'startDate' && updated.endDate && updated.endDate < value) {
        updated.endDate = value;
      }
      if (name === 'endDate' && value < updated.startDate) {
        updated.startDate = value;
      }
      return updated;
    });
  };

  const handleAdditionalProductChange = (e) => {
    const { name, checked } = e.target;
    setReservationDetails((prev) => {
      let updated = [...prev.additionalProducts];
      if (checked) {
        updated.push(name);
      } else {
        updated = updated.filter((item) => item !== name);
      }
      return { ...prev, additionalProducts: updated };
    });
  };

  // Lógica para calcular el precio total
  const calculateTotalPrice = () => {
    let days = 0;
    if (reservationDetails.startDate && reservationDetails.endDate) {
      days = calculateDaysDifference(reservationDetails.startDate, reservationDetails.endDate);
      if (days < 1) days = 1;
    }
    setOriginalDays(days);
    setSelectedDays(days);

    let total = 0;
    const planes = data.planesTarifas || {};

    if (planType && reservationDetails.planOption) {
      let planPrice = 0;
      // Buscamos el plan exacto en la categoría correspondiente
      if (planType === 'alquilerPorHoras') {
        // Plan Horas => se asume que es un valor fijo (24h).
        // Se busca en 'alquilerPorHoras' un plan con .horas === planOption
        const selectedPlan = planes.alquilerPorHoras?.find(
          (p) => String(p.horas) === reservationDetails.planOption
        );
        if (selectedPlan) {
          // Por regla, plan por horas NO se multiplica por días.
          // Se usa el valor "tarifaCOP" tal cual (ej: 70,000 por 24h)
          planPrice = parseFloat(selectedPlan.tarifaCOP) || 0;
        }
      } 
      else if (planType === 'alquilerPorDias') {
        // Plan por Días => multiplica la tarifa por los días
        const selectedPlan = planes.alquilerPorDias?.find(
          (p) => String(p.rangoDias) === reservationDetails.planOption
        );
        if (selectedPlan) {
          planPrice = (parseFloat(selectedPlan.tarifaCOP) || 0) * days;
        }
      }
      else if (planType === 'planesSemanales') {
        const selectedPlan = planes.planesSemanales?.find(
          (p) => String(p.kilometros) === reservationDetails.planOption
        );
        // Restricción: no puede pasar de 7 días
        if (days > 7) {
          // Podríamos setear un error o avisar al usuario
          planPrice = 0; 
          // O forzamos a 7, pero según tu lógica, se indicaría un error.
        } else if (selectedPlan) {
          // Multiplica la tarifaCOP por el # de días
          const subtotal = (parseFloat(selectedPlan.tarifaCOP) || 0) * days;
          // Si hay un "precioSemanalFijo", tomamos el menor
          const maxSemanal = selectedPlan.precioSemanalFijo ? parseFloat(selectedPlan.precioSemanalFijo) : Infinity;
          planPrice = Math.min(subtotal, maxSemanal);
        }
      }
      else if (planType === 'planesQuincenales') {
        const selectedPlan = planes.planesQuincenales?.find(
          (p) => String(p.kilometros) === reservationDetails.planOption
        );
        // Restricción: no puede pasar de 15 días
        if (days > 15) {
          planPrice = 0;
          // O mostrar un error. 
        } else if (selectedPlan) {
          const subtotal = (parseFloat(selectedPlan.tarifaCOP) || 0) * days;
          const maxQuincenal = selectedPlan.precioQuincenalFijo ? parseFloat(selectedPlan.precioQuincenalFijo) : Infinity;
          planPrice = Math.min(subtotal, maxQuincenal);
        }
      }
      else if (planType === 'planesMensuales') {
        // Sin límite de días
        const selectedPlan = planes.planesMensuales?.find(
          (p) => String(p.kilometros) === reservationDetails.planOption
        );
        if (selectedPlan) {
          planPrice = (parseFloat(selectedPlan.tarifaCOP) || 0) * days;
        }
      }
      total += planPrice;
    }

    // Sumar productos adicionales
    reservationDetails.additionalProducts.forEach((productName) => {
      const product = additionalProductsList.find((item) => item.name === productName);
      if (product) {
        if (product.perDay) {
          total += product.price * days;
        } else {
          total += product.price;
        }
      }
    });

    setTotalPrice(total);
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [reservationDetails, data]);

  // Manejar envío del formulario
  const handleReservationSubmit = (e) => {
    e.preventDefault();

    if (
      !reservationDetails.planType ||
      !reservationDetails.startDate ||
      !reservationDetails.endDate ||
      !reservationDetails.startTime ||
      !reservationDetails.endTime ||
      !reservationDetails.planOption ||
      !reservationDetails.city
    ) {
      alert('Por favor, completa todos los campos de la reserva.');
      return;
    }

    if (data.stock <= 0) {
      alert('Lo sentimos, este producto está agotado.');
      return;
    }

    const startDateTime = new Date(`${reservationDetails.startDate}T${reservationDetails.startTime}`);
    const endDateTime = new Date(`${reservationDetails.endDate}T${reservationDetails.endTime}`);
    if (endDateTime < startDateTime) {
      alert('La fecha/hora de entrega no puede ser anterior a la de inicio.');
      return;
    }

    // Construimos la info de la reserva
    const reservationInfo = {
      productName: data.productName,
      productImage: data.productImage[0] || '',
      category: data.category || '',
      planType: reservationDetails.planType,
      planOption: reservationDetails.planOption,
      startDate: reservationDetails.startDate,
      startTime: reservationDetails.startTime,
      endDate: reservationDetails.endDate,
      endTime: reservationDetails.endTime,
      additionalProducts: reservationDetails.additionalProducts,
      totalPrice,
      city: reservationDetails.city || 'Ciudad no especificada',
      selectedDays,
      originalDays,
    };

    dispatch(addToCart({ productId: data._id, reservationDetails: reservationInfo }));
    navigate('/cart');
  };

  return (
    <div className="product-details-container">
      <div className="product-details-wrapper">

        {/* LEFT SECTION */}
        <div className="left-section">
          <div className="product-images">
            <div className="product-main-image" onMouseLeave={handleLeaveImageZoom}>
              <img
                src={activeImage || 'ruta/por/defecto.jpg'}
                className="main-image"
                onMouseMove={handleZoomImage}
                alt={data?.productName || 'Producto'}
              />
              {zoomImage && activeImage && (
                <div className="zoomed-image">
                  <div
                    className="zoomed-content"
                    style={{
                      backgroundImage: `url(${activeImage})`,
                      backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="product-thumbnails">
              {loading ? (
                new Array(4).fill(null).map((_, idx) => (
                  <div className="thumbnail-loading" key={idx} />
                ))
              ) : (
                data?.productImage?.map((imgURL, idx) => (
                  <img
                    src={imgURL}
                    className="thumbnail-image"
                    key={idx}
                    onMouseEnter={() => handleMouseEnterProduct(imgURL)}
                    onClick={() => handleMouseEnterProduct(imgURL)}
                    alt={`Thumbnail ${idx + 1}`}
                  />
                ))
              )}
            </div>
          </div>

          <div className="additional-products">
            <h4>Productos Adicionales</h4>
            {additionalProductsList.map((product, index) => (
              <div key={index} className="additional-product-item">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name={product.name}
                    onChange={handleAdditionalProductChange}
                    checked={reservationDetails.additionalProducts.includes(product.name)}
                  />
                  {product.name}: {displayCOPCurrency(product.price)} {product.unit}
                  {product.perDay ? '/día' : ''}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* RIGHT SECTION */}
        {loading ? (
          <div className="product-info-loading">
            <p>Cargando detalles del producto...</p>
          </div>
        ) : (
          <div className="right-section">
            <div className="product-info">
              <p className="brand-name">{data?.brandName || 'Marca desconocida'}</p>
              <h2 className="product-title">{data?.productName || 'Producto'}</h2>
              <p className="product-category">{data?.category || 'Categoría desconocida'}</p>
              <div className="product-pricing">
                <p className="selling-price">
                  {displayCOPCurrency(data.sellingPrice || 0)}
                </p>
                <p className="original-price">{displayCOPCurrency(data.price || 0)}</p>
              </div>
            </div>

            {data.stock > 0 ? (
              <div className="reservation-form">
                <h3>Reserva tu plan</h3>
                <form onSubmit={handleReservationSubmit}>
                  <div className="form-group">
                    <label htmlFor="planType">Tipo de Plan:</label>
                    <select
                      id="planType"
                      name="planType"
                      value={reservationDetails.planType}
                      onChange={handleReservationChange}
                      required
                    >
                      <option value="">Seleccione un plan</option>
                      {data.planesTarifas?.alquilerPorHoras?.length > 0 && (
                        <option value="alquilerPorHoras">Alquiler por Horas</option>
                      )}
                      {data.planesTarifas?.alquilerPorDias?.length > 0 && (
                        <option value="alquilerPorDias">Alquiler por Días</option>
                      )}
                      {data.planesTarifas?.planesSemanales?.length > 0 && (
                        <option value="planesSemanales">Planes Semanales</option>
                      )}
                      {data.planesTarifas?.planesQuincenales?.length > 0 && (
                        <option value="planesQuincenales">Planes Quincenales</option>
                      )}
                      {data.planesTarifas?.planesMensuales?.length > 0 && (
                        <option value="planesMensuales">Planes Mensuales</option>
                      )}
                    </select>
                  </div>

                  {planType && (
                    <div className="form-group">
                      <label htmlFor="planOption">Opciones del Plan:</label>
                      <select
                        id="planOption"
                        name="planOption"
                        value={reservationDetails.planOption}
                        onChange={handleReservationChange}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {planType === 'alquilerPorHoras' &&
                          data.planesTarifas.alquilerPorHoras
                            ?.filter(plan => plan.horas !== undefined && plan.horas !== null)
                            .map((plan, idx) => (
                              <option key={idx} value={String(plan.horas)}>
                                {plan.horas} horas - {displayCOPCurrency(plan.tarifaCOP)} COP
                              </option>
                            ))
                        }
                        {planType === 'alquilerPorDias' &&
                          data.planesTarifas.alquilerPorDias
                            ?.filter(plan => plan.rangoDias !== undefined && plan.rangoDias !== null)
                            .map((plan, idx) => (
                              <option key={idx} value={String(plan.rangoDias)}>
                                {plan.rangoDias} días - {displayCOPCurrency(plan.tarifaCOP)} COP/día
                              </option>
                            ))
                        }
                        {planType === 'planesSemanales' &&
                          data.planesTarifas.planesSemanales
                            ?.filter(plan => plan.kilometros !== undefined && plan.kilometros !== null)
                            .map((plan, idx) => (
                              <option key={idx} value={String(plan.kilometros)}>
                                {plan.kilometros} km - {displayCOPCurrency(plan.tarifaCOP)} COP/día
                              </option>
                            ))
                        }
                        {planType === 'planesQuincenales' &&
                          data.planesTarifas.planesQuincenales
                            ?.filter(plan => plan.kilometros !== undefined && plan.kilometros !== null)
                            .map((plan, idx) => (
                              <option key={idx} value={String(plan.kilometros)}>
                                {plan.kilometros} km - {displayCOPCurrency(plan.tarifaCOP)} COP/día
                              </option>
                            ))
                        }
                        {planType === 'planesMensuales' &&
                          data.planesTarifas.planesMensuales
                            ?.filter(plan => plan.kilometros !== undefined && plan.kilometros !== null)
                            .map((plan, idx) => (
                              <option key={idx} value={String(plan.kilometros)}>
                                {plan.kilometros} km - {displayCOPCurrency(plan.tarifaCOP)} COP/día
                              </option>
                            ))
                        }
                      </select>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="city">Ciudad:</label>
                    <select
                      id="city"
                      name="city"
                      value={reservationDetails.city}
                      onChange={handleReservationChange}
                      required
                    >
                      <option value="">Seleccione una ciudad</option>
                      <option value="Medellin">Medellín</option>
                      <option value="Bogota">Bogotá</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="startDate">Fecha de Inicio:</label>
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      value={reservationDetails.startDate}
                      onChange={handleReservationChange}
                      min={todayDate}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="startTime">Hora de Inicio:</label>
                    <input
                      type="time"
                      id="startTime"
                      name="startTime"
                      value={reservationDetails.startTime}
                      onChange={handleReservationChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="endDate">Fecha de Fin:</label>
                    <input
                      type="date"
                      id="endDate"
                      name="endDate"
                      value={reservationDetails.endDate}
                      onChange={handleReservationChange}
                      min={reservationDetails.startDate || todayDate}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="endTime">Hora de Entrega:</label>
                    <input
                      type="time"
                      id="endTime"
                      name="endTime"
                      value={reservationDetails.endTime}
                      onChange={handleReservationChange}
                      required
                    />
                  </div>

                  {(['alquilerPorDias','planesSemanales','planesQuincenales','planesMensuales'].includes(planType)) && (
                    <div className="form-group">
                      <label>Días seleccionados:</label>
                      <input
                        type="number"
                        value={selectedDays}
                        readOnly
                        className="days-selected-input"
                      />
                    </div>
                  )}

                  <div className="total-price mt-4">
                    <p className="font-semibold">
                      Total: {displayCOPCurrency(totalPrice)} COP
                    </p>
                  </div>

                  <div className="form-buttons mt-4">
                    <button 
                      type="submit"
                      className={`reserve-button px-4 py-2 rounded ${data.stock > 0 ? 'bg-red-600 hover:bg-red-700 text-white' : 'bg-gray-400 text-white cursor-not-allowed'}`}
                      disabled={data.stock <= 0}
                    >
                      Reservar
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="no-stock-message mt-4">
                <p className="text-red-600 font-semibold text-lg text-center">
                  Lo sentimos, este producto está agotado y no puede ser reservado.
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Sección de Tarifas */}
      {!loading && data?.planesTarifas && (
        <div className="tarifas-section mt-8">
          <h2 className="tarifas-title">Planes y Tarifas</h2>

          {/* Alquiler por Horas */}
          {data.planesTarifas.alquilerPorHoras?.length > 0 && (
            <div className="tarifas-plan mt-4">
              <h3>Alquiler por Horas</h3>
              <table className="tarifas-table w-full mt-2">
                <thead>
                  <tr>
                    <th>Horas</th>
                    <th>Tarifa COP</th>
                    <th>Tarifa USD</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.alquilerPorHoras.map((plan, i) => (
                    <tr key={i}>
                      <td>{plan.horas}</td>
                      <td>{displayCOPCurrency(plan.tarifaCOP)}</td>
                      <td>{plan.tarifaUSD !== null ? displayCOPCurrency(plan.tarifaUSD) : 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Alquiler por Días */}
          {data.planesTarifas.alquilerPorDias?.length > 0 && (
            <div className="tarifas-plan mt-4">
              <h3>Alquiler por Días</h3>
              <table className="tarifas-table w-full mt-2">
                <thead>
                  <tr>
                    <th>Rango de Días</th>
                    <th>Tarifa COP/día</th>
                    <th>Tarifa USD/día</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.alquilerPorDias.map((plan, i) => (
                    <tr key={i}>
                      <td>{plan.rangoDias}</td>
                      <td>{displayCOPCurrency(plan.tarifaCOP)}</td>
                      <td>{plan.tarifaUSD !== null ? displayCOPCurrency(plan.tarifaUSD) : 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Planes Semanales */}
          {data.planesTarifas.planesSemanales?.length > 0 && (
            <div className="tarifas-plan mt-4">
              <h3>Planes Semanales</h3>
              <table className="tarifas-table w-full mt-2">
                <thead>
                  <tr>
                    <th>Kilómetros</th>
                    <th>Tarifa COP/día</th>
                    <th>Tope Semanal (Opcional)</th>
                    <th>Tarifa USD/día</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.planesSemanales.map((plan, i) => (
                    <tr key={i}>
                      <td>{plan.kilometros}</td>
                      <td>{displayCOPCurrency(plan.tarifaCOP)}</td>
                      <td>{plan.precioSemanalFijo ? displayCOPCurrency(plan.precioSemanalFijo) : 'N/A'}</td>
                      <td>{plan.tarifaUSD !== null ? displayCOPCurrency(plan.tarifaUSD) : 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Planes Quincenales */}
          {data.planesTarifas.planesQuincenales?.length > 0 && (
            <div className="tarifas-plan mt-4">
              <h3>Planes Quincenales</h3>
              <table className="tarifas-table w-full mt-2">
                <thead>
                  <tr>
                    <th>Kilómetros</th>
                    <th>Tarifa COP/día</th>
                    <th>Tope Quincenal (Opcional)</th>
                    <th>Tarifa USD/día</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.planesQuincenales.map((plan, i) => (
                    <tr key={i}>
                      <td>{plan.kilometros}</td>
                      <td>{displayCOPCurrency(plan.tarifaCOP)}</td>
                      <td>{plan.precioQuincenalFijo ? displayCOPCurrency(plan.precioQuincenalFijo) : 'N/A'}</td>
                      <td>{plan.tarifaUSD !== null ? displayCOPCurrency(plan.tarifaUSD) : 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Planes Mensuales */}
          {data.planesTarifas.planesMensuales?.length > 0 && (
            <div className="tarifas-plan mt-4">
              <h3>Planes Mensuales</h3>
              <table className="tarifas-table w-full mt-2">
                <thead>
                  <tr>
                    <th>Kilómetros</th>
                    <th>Tarifa COP/día</th>
                    <th>Tarifa USD/día</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.planesMensuales.map((plan, i) => (
                    <tr key={i}>
                      <td>{plan.kilometros}</td>
                      <td>{displayCOPCurrency(plan.tarifaCOP)}</td>
                      <td>{plan.tarifaUSD !== null ? displayCOPCurrency(plan.tarifaUSD) : 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Adicionales */}
          <div className="tarifas-adicionales mt-4">
            <h3>Adicionales</h3>
            <ul>
              <li>
                Kilómetro Adicional: {displayCOPCurrency(data.planesTarifas.adicionales.kilometroAdicionalCOP || 0)} COP
              </li>
              <li>
                Hora Adicional: {displayCOPCurrency(data.planesTarifas.adicionales.horaAdicionalCOP || 0)} COP
              </li>
              <li>
                Depósito/Fianza: {displayCOPCurrency(data.planesTarifas.adicionales.depositoFianzaCOP || 0)} COP
              </li>
            </ul>
          </div>
        </div>
      )}

      <h2 className="related-products-title mt-8">Otras Motos</h2>
      <CategoryWiseProductDisplay
        category={data?.category}
        currentProductId={data?._id}
        heading="Productos Relacionados"
      />

      {/* Especificaciones Técnicas */}
      <div className="banner mt-8">
        <h3 className="banner-title">Especificaciones Técnicas</h3>
      </div>
      <div className="specifications-table-container mt-4">
        <table className="specifications-table w-full">
          <tbody>
            <tr>
              <td><TbCircuitMotor /></td>
              <th>Motor:</th>
              <td>{data.motor || 'N/A'}</td>
            </tr>
            <tr>
              <td><TbCylinderPlus /></td>
              <th>Cilindrada:</th>
              <td>{data.cilindrada || 'N/A'}</td>
            </tr>
            <tr>
              <td><AiOutlineSisternode /></td>
              <th>Alimentación:</th>
              <td>{data.alimentacion || 'N/A'}</td>
            </tr>
            <tr>
              <td><SiVirginmedia /></td>
              <th>Diámetro x Carrera:</th>
              <td>{data.diametroXCarrera || 'N/A'}</td>
            </tr>
            <tr>
              <td><GiHorseHead /></td>
              <th>Potencia:</th>
              <td>{data.potencia || 'N/A'}</td>
            </tr>
            <tr>
              <td><CgChanel /></td>
              <th>Torque Máximo:</th>
              <td>{data.torqueMaximo || 'N/A'}</td>
            </tr>
            <tr>
              <td><SlEnergy /></td>
              <th>Arranque:</th>
              <td>{data.arranque || 'N/A'}</td>
            </tr>
            <tr>
              <td><GiGasPump /></td>
              <th>Capacidad de Combustible:</th>
              <td>{data.capacidadCombustible || 'N/A'}</td>
            </tr>
            <tr>
              <td><GiSpeedometer /></td>
              <th>Transmisión:</th>
              <td>{data.transmision || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="banner mt-8">
        <h3 className="banner-title">Lo incluido dentro de las tarifas</h3>
      </div>
      <div className="inclusions-table-container mt-4">
        <table className="inclusions-table w-full">
          <thead>
            <tr>
              <th>Elemento</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><GiFullMotorcycleHelmet /> Cascos</td>
              <td>2</td>
            </tr>
            <tr>
              <td><GiLifeJacket /> Chalecos reflectivos</td>
              <td>2</td>
            </tr>
            {data.maletero === 'Sí' && (
              <tr>
                <td><FaBoxArchive /> Baúl de {data.baulCapacidad}</td>
                <td>1</td>
              </tr>
            )}
            <tr>
              <td><IoPhonePortraitSharp /> Soporte para celular</td>
              <td>1</td>
            </tr>
            <tr>
              <td><RiSecurePaymentFill /> Seguro contra robo (15% deducible)</td>
              <td>✔</td>
            </tr>
            <tr>
              <td><MdPermPhoneMsg /> Asistencia Telefónica 24/7</td>
              <td>✔</td>
            </tr>
            <tr>
              <td><GiColombia /> Mantenimientos a nivel Nacional</td>
              <td>✔</td>
            </tr>
            <tr>
              <td><RiSecurePaymentFill /> Pérdidas Totales (15% deducible)</td>
              <td>✔</td>
            </tr>
            <tr>
              <td><TbCarCrane /> Grúa 24/7 a nivel nacional</td>
              <td>✔</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="final-section mt-8">
        <h2 className="final-section-title">
          ¡Listo para desatar la emoción sobre dos ruedas!
        </h2>
        <div className="final-section-content mt-4">
          <a 
            href="/contacto"
            className="final-section-button bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
          >
            ¡Comunícate por WhatsApp!
          </a>
          <p className="final-section-text mt-2">
            {data?.description || 'Descripción del producto no disponible.'}
          </p>
        </div>
      </div>
    </div>
  );
};

ProductDetails.propTypes = {
  // Define PropTypes si es necesario
};

export default ProductDetails;
