
import { useState, useEffect } from 'react';
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import uploadImage from '../helpers/uploadImage';
import DisplayImage from './DisplayImage';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import productCategory from '../helpers/productCategory';

const UploadProduct = ({ onClose, fetchData }) => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
    stock: 0,
    motor: "",
    cilindrada: "",
    alimentacion: "",
    diametroXCarrera: "",
    potencia: "",
    torqueMaximo: "",
    arranque: "",
    capacidadCombustible: "",
    transmision: "",
    maletero: "No",
    baulCapacidad: "40 Litros",
    planesTarifas: {
  
      alquilerPorHoras: [],
      alquilerPorDias: [],
      planesSemanales: [],
      planesQuincenales: [],
      planesMensuales: [],
      adicionales: {
        kilometroAdicionalCOP: null,
        horaAdicionalCOP: null,
        depositoFianzaCOP: null,
      },
      comentario: "",
    },
    exchangeRate: null, 
  });

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState("");
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: name === 'exchangeRate' ? (value !== '' ? parseFloat(value) : null) : value,
    }));
  };
  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      const uploadImageCloudinary = await uploadImage(file);
      setData((prev) => ({
        ...prev,
        productImage: [...prev.productImage, uploadImageCloudinary.url],
      }));
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      toast.error('Error al subir la imagen.');
    }
  };
  const handleDeleteProductImage = (index) => {
    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);
    setData((prev) => ({
      ...prev,
      productImage: newProductImage,
    }));
  };
  const handlePlanesTarifasChange = (e, planCategory, index, field) => {
    const { value } = e.target;
    setData((prev) => {
      const updatedPlans = [...prev.planesTarifas[planCategory]];
      const plan = { ...updatedPlans[index] };
      if (field === 'tarifaCOP') {
        plan.tarifaCOP = value !== '' ? parseFloat(value) : null;
        plan.tarifaUSD = (plan.tarifaCOP !== null && prev.exchangeRate)
          ? parseFloat((plan.tarifaCOP / prev.exchangeRate).toFixed(2))
          : null;
      } else if (field === 'precioSemanalFijo' || field === 'precioQuincenalFijo') {
        plan[field] = value !== '' ? parseFloat(value) : null;
      } else {
        plan[field] = value;
      }
      updatedPlans[index] = plan;
      return {
        ...prev,
        planesTarifas: {
          ...prev.planesTarifas,
          [planCategory]: updatedPlans,
        },
      };
    });
  };
  const handleAddPlan = (planCategory, initialValues = {}) => {
    const tarifaUSD = (initialValues.tarifaCOP !== null && initialValues.tarifaCOP !== undefined && data.exchangeRate)
      ? parseFloat((initialValues.tarifaCOP / data.exchangeRate).toFixed(2))
      : null;

    setData((prev) => ({
      ...prev,
      planesTarifas: {
        ...prev.planesTarifas,
        [planCategory]: [
          ...prev.planesTarifas[planCategory],
          {
            ...initialValues,
            tarifaCOP: initialValues.tarifaCOP !== undefined ? initialValues.tarifaCOP : null,
            tarifaUSD: tarifaUSD,
          },
        ],
      },
    }));
  };
  const handleDeletePlan = (planCategory, index) => {
    setData((prev) => {
      const updatedPlans = [...prev.planesTarifas[planCategory]];
      updatedPlans.splice(index, 1);
      return {
        ...prev,
        planesTarifas: {
          ...prev.planesTarifas,
          [planCategory]: updatedPlans,
        },
      };
    });
  };
  const handleComentarioChange = (e) => {
    const { value } = e.target;
    setData((prev) => ({
      ...prev,
      planesTarifas: {
        ...prev.planesTarifas,
        comentario: value,
      },
    }));
  };
  useEffect(() => {
    const { exchangeRate, planesTarifas } = data;
    const parsedExchangeRate = parseFloat(exchangeRate);

    if (parsedExchangeRate > 0) {
     
      const updatedAlquilerPorHoras = planesTarifas.alquilerPorHoras.map(plan => ({
        ...plan,
        tarifaUSD: (plan.tarifaCOP !== null && !isNaN(plan.tarifaCOP))
          ? parseFloat((plan.tarifaCOP / parsedExchangeRate).toFixed(2))
          : null,
      }));
      const updatedAlquilerPorDias = planesTarifas.alquilerPorDias.map(plan => ({
        ...plan,
        tarifaUSD: (plan.tarifaCOP !== null && !isNaN(plan.tarifaCOP))
          ? parseFloat((plan.tarifaCOP / parsedExchangeRate).toFixed(2))
          : null,
      }));
      const updatedPlanesSemanales = planesTarifas.planesSemanales.map(plan => ({
        ...plan,
        tarifaUSD: (plan.tarifaCOP !== null && !isNaN(plan.tarifaCOP))
          ? parseFloat((plan.tarifaCOP / parsedExchangeRate).toFixed(2))
          : null,
      }));
      const updatedPlanesQuincenales = planesTarifas.planesQuincenales.map(plan => ({
        ...plan,
        tarifaUSD: (plan.tarifaCOP !== null && !isNaN(plan.tarifaCOP))
          ? parseFloat((plan.tarifaCOP / parsedExchangeRate).toFixed(2))
          : null,
      }));
      const updatedPlanesMensuales = planesTarifas.planesMensuales.map(plan => ({
        ...plan,
        tarifaUSD: (plan.tarifaCOP !== null && !isNaN(plan.tarifaCOP))
          ? parseFloat((plan.tarifaCOP / parsedExchangeRate).toFixed(2))
          : null,
      }));

      setData((prev) => ({
        ...prev,
        planesTarifas: {
          ...prev.planesTarifas,
          alquilerPorHoras: updatedAlquilerPorHoras,
          alquilerPorDias: updatedAlquilerPorDias,
          planesSemanales: updatedPlanesSemanales,
          planesQuincenales: updatedPlanesQuincenales,
          planesMensuales: updatedPlanesMensuales,
        },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        planesTarifas: {
          ...prev.planesTarifas,
          alquilerPorHoras: prev.planesTarifas.alquilerPorHoras.map(plan => ({ ...plan, tarifaUSD: null })),
          alquilerPorDias: prev.planesTarifas.alquilerPorDias.map(plan => ({ ...plan, tarifaUSD: null })),
          planesSemanales: prev.planesTarifas.planesSemanales.map(plan => ({ ...plan, tarifaUSD: null })),
          planesQuincenales: prev.planesTarifas.planesQuincenales.map(plan => ({ ...plan, tarifaUSD: null })),
          planesMensuales: prev.planesTarifas.planesMensuales.map(plan => ({ ...plan, tarifaUSD: null })),
        },
      }));
    }
  }, [
    data.exchangeRate,
    data.planesTarifas.alquilerPorHoras,
    data.planesTarifas.alquilerPorDias,
    data.planesTarifas.planesSemanales,
    data.planesTarifas.planesQuincenales,
    data.planesTarifas.planesMensuales
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.productImage.length === 0) {
      toast.error('Por favor, sube al menos una imagen del producto.');
      return;
    }

    try {
      const response = await fetch(SummaryApi.uploadProduct.url, {
        method: SummaryApi.uploadProduct.method,
        credentials: 'include',
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (responseData.success) {
        toast.success(responseData.message);
        onClose();
        fetchData();
      } else if (responseData.error) {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error('Error al subir el producto:', error);
      toast.error('Error al subir el producto.');
    }
  };

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Subir Motocicleta</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
        
          <label htmlFor='exchangeRate' className='font-bold'>Tasa de Cambio (COP/USD) :</label>
          <input
            type='number'
            id='exchangeRate'
            placeholder='Ej: 3700'
            name='exchangeRate'
            value={data.exchangeRate !== null ? data.exchangeRate : ''}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            min="1"
            step="any"
            required
          />
          <label htmlFor='productName'>Nombre del producto :</label>
          <input
            type='text'
            id='productName'
            placeholder='Ej: Moto XYZ'
            name='productName'
            value={data.productName}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />
          <label htmlFor='brandName' className='mt-3'>Marca :</label>
          <input
            type='text'
            id='brandName'
            placeholder='Ej: Yamaha'
            value={data.brandName}
            name='brandName'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />
          <label htmlFor='category' className='mt-3'>Categoría :</label>
          <select
            required
            value={data.category}
            name='category'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          >
            <option value="">Seleccionar Categoría</option>
            {productCategory.map((el, index) => (
              <option value={el.value} key={el.value + index}>
                {el.label}
              </option>
            ))}
          </select>
          <label htmlFor='stock' className='mt-3'>Stock :</label>
          <input
            type='number'
            id='stock'
            placeholder='Cantidad disponible'
            value={data.stock}
            name='stock'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
            min="0"
          />
          <label htmlFor='motor' className='mt-3'>Motor :</label>
          <input
            type='text'
            id='motor'
            placeholder='Tipo de motor'
            name='motor'
            value={data.motor}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='cilindrada' className='mt-3'>Cilindrada :</label>
          <input
            type='text'
            id='cilindrada'
            placeholder='Cilindrada'
            name='cilindrada'
            value={data.cilindrada}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='alimentacion' className='mt-3'>Alimentación :</label>
          <input
            type='text'
            id='alimentacion'
            placeholder='Ej: Inyección'
            name='alimentacion'
            value={data.alimentacion}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='diametroXCarrera' className='mt-3'>Diámetro x Carrera :</label>
          <input
            type='text'
            id='diametroXCarrera'
            name='diametroXCarrera'
            placeholder='Ej: 58 x 47 mm'
            value={data.diametroXCarrera}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='potencia' className='mt-3'>Potencia :</label>
          <input
            type='text'
            id='potencia'
            placeholder='Ej: 15 HP'
            name='potencia'
            value={data.potencia}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='torqueMaximo' className='mt-3'>Torque Máximo :</label>
          <input
            type='text'
            id='torqueMaximo'
            placeholder='Ej: 13 Nm'
            name='torqueMaximo'
            value={data.torqueMaximo}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='arranque' className='mt-3'>Arranque :</label>
          <input
            type='text'
            id='arranque'
            name='arranque'
            placeholder='Ej: Eléctrico'
            value={data.arranque}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='capacidadCombustible' className='mt-3'>Capacidad de Combustible :</label>
          <input
            type='text'
            id='capacidadCombustible'
            name='capacidadCombustible'
            placeholder='Ej: 12 L'
            value={data.capacidadCombustible}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='transmision' className='mt-3'>Transmisión :</label>
          <input
            type='text'
            id='transmision'
            name='transmision'
            placeholder='Ej: 5 Velocidades'
            value={data.transmision}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='maletero' className='mt-3'>Maletero :</label>
          <select
            name='maletero'
            value={data.maletero}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          >
            <option value="No">No</option>
            <option value="Sí">Sí</option>
          </select>

          <label htmlFor='baulCapacidad' className='mt-3'>Baúl Capacidad :</label>
          <input
            type='text'
            id='baulCapacidad'
            name='baulCapacidad'
            placeholder='Ej: 40 Litros'
            value={data.baulCapacidad}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />
          <label className='mt-3 font-bold'>Planes Tarifas - Alquiler por Horas :</label>
          {data.planesTarifas.alquilerPorHoras.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Horas:</label>
              <input
                type='number'
                name='horas'
                placeholder='Ingrese las horas (24 para 1 día)'
                value={plan.horas || ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'alquilerPorHoras', index, 'horas')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
                min="0"
              />

              <label>Tarifa COP (fijo para esas horas):</label>
              <input
                type='number'
                name='tarifaCOP'
                placeholder='Ej: 70000'
                value={plan.tarifaCOP !== null ? plan.tarifaCOP : ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'alquilerPorHoras', index, 'tarifaCOP')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
                min="0"
                step="any"
              />

              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                placeholder='Automático según tasa de cambio'
                value={plan.tarifaUSD !== null ? plan.tarifaUSD : ''}
                readOnly
                className='p-1 bg-slate-200 border rounded w-full cursor-not-allowed'
              />

              <button
                type='button'
                onClick={() => handleDeletePlan('alquilerPorHoras', index)}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}
          <button
            type='button'
            onClick={() => handleAddPlan('alquilerPorHoras', { horas: '', tarifaCOP: null })}
            className='text-blue-600 mb-2'
          >
            Agregar Plan de Alquiler por Horas
          </button>
          <label className='mt-3 font-bold'>Planes Tarifas - Alquiler por Días :</label>
          {data.planesTarifas.alquilerPorDias.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Rango de Días:</label>
              <input
                type='text'
                name='rangoDias'
                placeholder='Ej: "2-11", "12-23", "24+"'
                value={plan.rangoDias || ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'alquilerPorDias', index, 'rangoDias')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
              />

              <label>Tarifa COP (por día):</label>
              <input
                type='number'
                name='tarifaCOP'
                placeholder='Ej: 60000'
                value={plan.tarifaCOP !== null ? plan.tarifaCOP : ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'alquilerPorDias', index, 'tarifaCOP')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
                min="0"
                step="any"
              />

              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                placeholder='Automático'
                value={plan.tarifaUSD !== null ? plan.tarifaUSD : ''}
                readOnly
                className='p-1 bg-slate-200 border rounded w-full cursor-not-allowed'
              />

              <button
                type='button'
                onClick={() => handleDeletePlan('alquilerPorDias', index)}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}
          <button
            type='button'
            onClick={() => handleAddPlan('alquilerPorDias', { rangoDias: '', tarifaCOP: null })}
            className='text-blue-600 mb-2'
          >
            Agregar Plan de Alquiler por Días
          </button>
          <label className='mt-3 font-bold'>Planes Tarifas - Planes Semanales :</label>
          {data.planesTarifas.planesSemanales.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Kilómetros (informativo, opcional):</label>
              <input
                type='text' 
                name='kilometros'
                placeholder='Ej: 250, 700, Ilimitado'
                value={plan.kilometros || ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesSemanales', index, 'kilometros')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
              />

              <label>Tarifa COP (por día dentro del plan semanal):</label>
              <input
                type='number'
                name='tarifaCOP'
                placeholder='Ej: 70000'
                value={plan.tarifaCOP !== null ? plan.tarifaCOP : ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesSemanales', index, 'tarifaCOP')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
                min="0"
                step="any"
              />
              <label>Precio Máximo Semanal (Fijo, opcional):</label>
              <input
                type='number'
                name='precioSemanalFijo'
                placeholder='Ej: 400000 (tope en 7 días)'
                value={plan.precioSemanalFijo !== null ? plan.precioSemanalFijo : ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesSemanales', index, 'precioSemanalFijo')}
                className='p-1 bg-slate-100 border rounded w-full'
                min="0"
                step="any"
              />
              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                placeholder='Automático'
                value={plan.tarifaUSD !== null ? plan.tarifaUSD : ''}
                readOnly
                className='p-1 bg-slate-200 border rounded w-full cursor-not-allowed'
              />

              <button
                type='button'
                onClick={() => handleDeletePlan('planesSemanales', index)}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}
          <button
            type='button'
            onClick={() => handleAddPlan('planesSemanales', { kilometros: '', tarifaCOP: null })}
            className='text-blue-600 mb-2'
          >
            Agregar Plan Semanal
          </button>
          <label className='mt-3 font-bold'>Planes Tarifas - Planes Quincenales :</label>
          {data.planesTarifas.planesQuincenales.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Kilómetros (informativo, opcional):</label>
              <input
                type='text'
                name='kilometros'
                placeholder='Ej: 1000, Ilimitado'
                value={plan.kilometros || ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesQuincenales', index, 'kilometros')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
              />

              <label>Tarifa COP (por día dentro del plan quincenal):</label>
              <input
                type='number'
                name='tarifaCOP'
                placeholder='Ej: 65000'
                value={plan.tarifaCOP !== null ? plan.tarifaCOP : ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesQuincenales', index, 'tarifaCOP')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
                min="0"
                step="any"
              />
              <label>Precio Máximo Quincenal (Fijo, opcional):</label>
              <input
                type='number'
                name='precioQuincenalFijo'
                placeholder='Ej: 900000 (tope en 15 días)'
                value={plan.precioQuincenalFijo !== null ? plan.precioQuincenalFijo : ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesQuincenales', index, 'precioQuincenalFijo')}
                className='p-1 bg-slate-100 border rounded w-full'
                min="0"
                step="any"
              />
              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                placeholder='Automático'
                value={plan.tarifaUSD !== null ? plan.tarifaUSD : ''}
                readOnly
                className='p-1 bg-slate-200 border rounded w-full cursor-not-allowed'
              />
              <button
                type='button'
                onClick={() => handleDeletePlan('planesQuincenales', index)}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}
          <button
            type='button'
            onClick={() => handleAddPlan('planesQuincenales', { kilometros: '', tarifaCOP: null })}
            className='text-blue-600 mb-2'
          >
            Agregar Plan Quincenal
          </button>
          <label className='mt-3 font-bold'>Planes Tarifas - Planes Mensuales :</label>
          {data.planesTarifas.planesMensuales.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Kilómetros (informativo, opcional):</label>
              <input
                type='text'
                name='kilometros'
                placeholder='Ej: 2000, Ilimitado'
                value={plan.kilometros || ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesMensuales', index, 'kilometros')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
              />

              <label>Tarifa COP (por día dentro del plan mensual):</label>
              <input
                type='number'
                name='tarifaCOP'
                placeholder='Ej: 60000'
                value={plan.tarifaCOP !== null ? plan.tarifaCOP : ''}
                onChange={(e) => handlePlanesTarifasChange(e, 'planesMensuales', index, 'tarifaCOP')}
                className='p-1 bg-slate-100 border rounded w-full'
                required
                min="0"
                step="any"
              />

              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                placeholder='Automático'
                value={plan.tarifaUSD !== null ? plan.tarifaUSD : ''}
                readOnly
                className='p-1 bg-slate-200 border rounded w-full cursor-not-allowed'
              />

              <button
                type='button'
                onClick={() => handleDeletePlan('planesMensuales', index)}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}
          <button
            type='button'
            onClick={() => handleAddPlan('planesMensuales', { kilometros: '', tarifaCOP: null })}
            className='text-blue-600 mb-2'
          >
            Agregar Plan Mensual
          </button>
          <label className='mt-3 font-bold'>Adicionales:</label>
          <label>Kilómetro Adicional COP:</label>
          <input
            type='number'
            name='kilometroAdicionalCOP'
            placeholder='Ej: 400'
            value={data.planesTarifas.adicionales.kilometroAdicionalCOP !== null ? data.planesTarifas.adicionales.kilometroAdicionalCOP : ''}
            onChange={(e) => {
              const { value } = e.target;
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  adicionales: {
                    ...prev.planesTarifas.adicionales,
                    kilometroAdicionalCOP: value !== '' ? parseFloat(value) : null,
                  },
                },
              }));
            }}
            className='p-1 bg-slate-100 border rounded w-full'
            required
            min="0"
            step="any"
          />

          <label>Hora Adicional COP:</label>
          <input
            type='number'
            name='horaAdicionalCOP'
            placeholder='Ej: 5000'
            value={data.planesTarifas.adicionales.horaAdicionalCOP !== null ? data.planesTarifas.adicionales.horaAdicionalCOP : ''}
            onChange={(e) => {
              const { value } = e.target;
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  adicionales: {
                    ...prev.planesTarifas.adicionales,
                    horaAdicionalCOP: value !== '' ? parseFloat(value) : null,
                  },
                },
              }));
            }}
            className='p-1 bg-slate-100 border rounded w-full'
            required
            min="0"
            step="any"
          />

          <label>Depósito/Fianza COP:</label>
          <input
            type='number'
            name='depositoFianzaCOP'
            placeholder='Ej: 300000'
            value={data.planesTarifas.adicionales.depositoFianzaCOP !== null ? data.planesTarifas.adicionales.depositoFianzaCOP : ''}
            onChange={(e) => {
              const { value } = e.target;
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  adicionales: {
                    ...prev.planesTarifas.adicionales,
                    depositoFianzaCOP: value !== '' ? parseFloat(value) : null,
                  },
                },
              }));
            }}
            className='p-1 bg-slate-100 border rounded w-full'
            required
            min="0"
            step="any"
          />
          <label className='mt-3 font-bold'>Comentarios Adicionales:</label>
          <textarea
            className='h-20 bg-slate-100 border resize-none p-1'
            placeholder='Detalles extra...'
            rows={3}
            onChange={handleComentarioChange}
            name='comentario'
            value={data.planesTarifas.comentario}
          ></textarea>
          <label htmlFor='productImage' className='mt-3'>Producto Imagen:</label>
          <label htmlFor='uploadImageInput'>
            <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'><FaCloudUploadAlt /></span>
                <p className='text-sm'>Subir Imagen del Producto</p>
                <input type='file' id='uploadImageInput' className='hidden' onChange={handleUploadProduct} />
              </div>
            </div>
          </label>
          <div>
            {data?.productImage.length > 0 ? (
              <div className='flex items-center gap-2 flex-wrap'>
                {data.productImage.map((el, index) => (
                  <div className='relative group' key={index}>
                    <img
                      src={el}
                      alt={`Imagen ${index + 1}`}
                      width={80}
                      height={80}
                      className='bg-slate-100 border cursor-pointer object-cover'
                      onClick={() => {
                        setOpenFullScreenImage(true);
                        setFullScreenImage(el);
                      }}
                    />
                    <div
                      className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer'
                      onClick={() => handleDeleteProductImage(index)}
                    >
                      <MdDelete />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className='text-red-600 text-xs'>*Por favor sube una imagen del producto</p>
            )}
          </div>

          <label htmlFor='price' className='mt-3'>Precio :</label>
          <input
            type='number'
            id='price'
            placeholder='Precio original'
            value={data.price}
            name='price'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
            min="0"
            step="any"
          />

          <label htmlFor='sellingPrice' className='mt-3'>Precio de venta :</label>
          <input
            type='number'
            id='sellingPrice'
            placeholder='Precio de venta o promoción'
            value={data.sellingPrice}
            name='sellingPrice'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
            min="0"
            step="any"
          />

          <label htmlFor='description' className='mt-3'>Descripción :</label>
          <textarea
            className='h-28 bg-slate-100 border resize-none p-1'
            placeholder='Descripción del producto...'
            rows={3}
            onChange={handleOnChange}
            name='description'
            value={data.description}
          ></textarea>

          <button className='px-3 py-2 bg-red-600 text-white mb-10 hover:bg-red-700'>
            Subir Producto
          </button>
        </form>
      </div>

      {openFullScreenImage && (
        <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />
      )}
    </div>
  );
};

export default UploadProduct;
