import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import SignUp from '../pages/SignUp';
import AdminPanel from '../pages/AdminPanel';
import AllUsers from '../pages/AllUsers';
import AllProducts from '../pages/AllProducts';
import CategoryProduct from '../pages/CategoryProduct';
import ProductDetails from '../pages/ProductDetails';
import Cart from '../pages/Cart';
import SearchProduct from '../pages/SearchProduct';
import Motos from '../pages/Motos';
import NotFound from '../pages/NotFound';
import Seguros from '../pages/Seguros';
import Rentar from '../pages/Rentar';
import Nosotros from '../pages/Nosotros';
import Contacto from '../pages/Contacto';
import Requisitos4Rent from '../pages/Requisitos4Rent'; 
import Condiciones4Rent from '../pages/Condiciones4Rent';
import PaymentSuccess from '../pages/PaymentSuccess';
import PaymentResponse from '../pages/PaymentResponse';
import PaymentFailure from '../pages/PaymentFailure';
import CancelPayment from '../pages/CancelPayment';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                element: <Home />
            },
            {
                path: "login",
                element: <Login />
            },

            {
                path: "Seguros",
                element: <Seguros />

            },
            {
                path: "Rentar",
                element: <Rentar />
            },
            {
                path: "NotFound",
                element: <NotFound />
            },
            {
                path: "*",
                element: <NotFound />
              },
            {
                path: "nosotros",
                element: <Nosotros />
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />
            },
            {
                path: "sign-up",
                element: <SignUp />
            },
            {
                path: "product-category",
                element: <CategoryProduct />
            },
            {
                path: "product/:id",
                element: <ProductDetails />
            },
            {
                path: "cart",
                element: <Cart />
            },
            {
                path: "payment-success",
                element: <PaymentSuccess />
            },
            {
                path: "payment-response",
                element: <PaymentResponse />
            },
            {
                path: "cancel-order",
                element: <CancelPayment />
            },
            {
                path: "payment-failure",
                element: <PaymentFailure />
            },
            {
                path: "search",
                element: <SearchProduct />
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />
            },
            {
                path: "Requisitos4Rent",
                element: <Requisitos4Rent />
            },
            {
                path: "Condiciones4Rent",
                element: <Condiciones4Rent />
            },
            {
                path: "motos",
                element: <Motos />
            },
            {
                path: "contacto",
                element: (
                  <>
                    <Contacto />
                  </>
                )
              },
            
            {
                path: "admin-panel",
                element: <AdminPanel />,
                children: [
                    {
                        path: "all-users",
                        element: <AllUsers />
                    },
                    {
                        path: "all-products",
                        element: <AllProducts />
                    }
                ]
            },
        ]
    }
]);

export default router;
